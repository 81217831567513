import React from "react"
import { ReactTyped } from 'react-typed';
import ParticlesBackground from "./ParticlesBackground";

function Intro(props) {

  const HeaderTitleTypeAnimation = React.memo((props) => {
    return <ReactTyped
      className="intro-text-slider"
      strings={props.titles}
      typeSpeed={100}
      backDelay={1100}
      backSpeed={30}
      loop
    />

  }, (props, prevProp) => true);

  return (
    <div id="Home">
      <div className="intro-content display-table">
        <div className="table-cell">
          <div id="ParticleContainer" className="container">
            <ParticlesBackground/> 
            <div id="ParticleText">
            <h1 className="intro-title mb-4">Hello, I am {props.info.firstName} {props.info.lastName}</h1>
            <p className="intro-subtitle">
              <span className="text-slider-items"></span>
              <strong className="text-slider">
                <HeaderTitleTypeAnimation titles={props.info.titles} />
              </strong> 
            </p>
            </div>
          </div> 
        </div>
       </div>
    </div>
  );
}

export default Intro;

